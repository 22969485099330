import React,{useEffect,useState} from 'react'
import axios from 'axios'
import Footer from '../common/footer';
export default function Live() {



  const [count, setCount] = useState(0);
  const [firstHalf, setFirstHalf] = useState(0);
  const [second, setSecond] = useState(0);

  const [third, setThird] = useState(0);

  const [fourth, setFourth] = useState(0);

  const [fifth, setFifth] = useState(0);

  const [sixth, setSxith] = useState(0);

  const [seven, setSeven] = useState(0);


  const [eight, seteEight] = useState(0);

  
  const [counter,setCounter]= useState(0);




  useEffect( () => {
   const timer = setTimeout(async() => {
 
     setCounter(counter+1)
     let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"live",time:10})
  
   },10000);
   return () => clearTimeout(timer);
 }, [counter])
    return (
        <div className="welcome  w-100 flex items-center justify-center" style={{height:'100vh',width:'100%'}}>
       
<div className="w-100 h-100  flex items-center justify-center">
<iframe src="https://player.vimeo.com/video/650329483" frameborder="0"  width="1920px" height="1080px" allow="autoplay; fullscreen" allowfullscreen></iframe>
</div>
<div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'92%' } }
        >
         
          <Footer />
        </div>  


        </div>
    )
}

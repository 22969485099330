import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Lobby from "../image/Lobby.png";
import PhotoBoothIcon from "../image/photobooth.png";
import MainExpo from "../image/mainexpo.png";
import SpeakerProfile from "../image/speakersprofile.png";
import ResourceaCenter from "../image/resource center.png";
import AuditoriumIcon from "../image/audo.png";
import agendaIcon from "../image/agendaicon.png";
import surveyIcon from "../image/Survey black.png";



export default function Footer() {
  const [stateProfile, setProfile] = useState(false);
  const [stateAgenda, setShowAgneda] = useState(false);

  const menuArr = [
    {
      name: "lobby",
      img: "../image/icon.svg",
    },
    {
      name: "auditorium",
      img: "../image/icon.svg",
    },
    {
      name: "Expo",
      img: "../image/icon.svg",
    },
    {
      name: "Breakout Hall",
      img: "../image/icon.svg",
    },
    {
      name: "photobooth",
      img: "../image/icon.svg",
    },
    {
      name: "photobooth",
      img: "../image/icon.svg",
    },
  ];
  const location = useLocation();
  // console.log("11==",location.pathname);
  return (
    location.pathname !== "/api" &&
    location.pathname !== "/" &&
    location.pathname !== "/leaderboard" && (
      <div
        className="flex items-center   justify-center w-100"
       
      >
        <img  className="h2-3 bottom-0 absolute  ml3 left-0 " src={require("../image/virtualLogo.png").default} alt="" />
        {stateProfile && (
          <div
            className="flex w-100 ba    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-50   flex justify-center items-center mh3 br3 overflow-hidden">
              <div className="w-100   flex  items-center justify-center bg-white">
                <div className="flex  backgroundJll flex-column items-center justify-center w-100 ">
                  <div  onClick={() => setProfile(false)} className=" backgroundJll h2 w-100 white flex items-center justify-center pointer ">
                 

                    <div
                      className="flex w-100 mr2  items-end f6 grey justify-end"
                      onClick={() => setProfile(false)}
                    >
                      X
                    </div>
                  </div>

                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../image/speaker.jpg").default}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {stateAgenda && (
          <div
            className="flex w-100    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-50   flex justify-center items-center br3 overflow-hidden">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex backgroundJll  flex-column items-center justify-center w-100 ">
                  <div className=" backgroundJll h2 w-100 white flex items-center justify-center pointer ">
                   

                    <div
                      className="flex w-100 mr2  items-end f6 grey justify-end"
                      onClick={() => setShowAgneda(false)}
                    >
                      X
                    </div>
                  </div>

                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../image/agendamenu.jpg").default}
                          alt=""
                          style={{ height: "auto"}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-10 flex flex-column z-999 items-center justify-center">
          <a href="/lobby">
            {" "}
            <img className="h2" src={Lobby} />
          </a>
          <div className="ttu f8 mt1  fw6">Lobby</div>
        </div>

        <div className="w-10 flex flex-column z-999 items-center justify-center">
          <a   href={location.pathname == "/live"? "/live":'auditorium'}>
            {" "}
            <img className="h2" src={AuditoriumIcon} />
          </a>
          <div className="ttu f8 mt1  fw6" >auditorium</div>
        </div>


        <div className="w-10 flex flex-column z-999 items-center justify-center">



          <a 
          href={(location.pathname !== "/expo"&& location.pathname !== "/zone1"&& location.pathname !== "/zone2"&&location.pathname !== "/zone3"
          &&location.pathname !== "/zone4"&&location.pathname !== "/zone5"&&location.pathname !== "/zone6"&&location.pathname !== "/zone7"
          &&location.pathname !== "/zone8"&&location.pathname !== "/zone9"&&location.pathname !== "/zone10"&&location.pathname !== "/zone11"
          &&location.pathname !== "/zone12"
          
          )? "/arenaentry":'arena'}
          >
         
            <img className="h2" src={MainExpo} />
          </a>
          <div className="ttu f8 mt1  fw6">SPONSORS ARENA</div>
        </div>


{/* 
        <div className="w-10 flex flex-column z-999 items-center justify-center">

        <a   href={location.pathname !== "/resource-center"? "/entryresource":'resource-center'}  >
            {" "}
            <img className="h2" src={ResourceaCenter} />
          </a>
          <div className="ttu f8  mt1 fw6">Resource center </div>
        </div> */}
        <div
          onClick={() => setShowAgneda(true)}
          className="w-10 flex flex-column z-999 items-center justify-center"
        >
          <img
            onClick={() => setShowAgneda(true)}
            className="h2"
            src={agendaIcon}
          />

          <div className="ttu f8 mt1  fw6">agenda</div>
        </div>

        <div className="w-10  pointer flex flex-column z-999 items-center justify-center">
          <img
            onClick={() => setProfile(true)}
            className="h2"
            src={SpeakerProfile}
          />

          <div className="ttu f8 mt1  fw6" onClick={() => setProfile(true)}>Speaker Profile</div>
        </div>
        <div className="w-10 flex flex-column z-999 items-center justify-center">
        <a   href={(location.pathname == "/photobooth")?"/photobooth":"photoboothentry"}>
            {" "}
            <img className="h2" src={PhotoBoothIcon} />
          </a>
          <div className="ttu f8 mt1  fw6">Photo Booth </div>
        </div>
  
      </div>
    )
  );
}

import React,{useState,useEffect} from 'react'
// import Auditorium from './auditorium';
import Video1 from "../audoe.mp4";
import { Link } from 'react-router-dom';
import Footer from '../common/footer';
import axios from 'axios';
export default function Auditorium() {


  useEffect(() => {
    const timer = setTimeout(() => {

      window.location.href="/live"
   
    }, 30000);
    return () => clearTimeout(timer);


    
  }, [])


  const [counter,setCounter]= useState(0);




  useEffect( () => {
   const timer = setTimeout(async() => {
 
     setCounter(counter+1)
     let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"audo",time:10})
  
   },10000);
   return () => clearTimeout(timer);
 }, [counter])

    const [stateMenu, setstateMenu] = useState(false);

  const [useS, useSet] = useState(`<video
    loop
    autoPlay
    playsinline
    preload
    
    
    src="${Video1}"
    class=${window.innerHeight >900 ? 'vide':window.innerHeight > 700?'videSmall':'videSmall' }
  />`);
  return (
    <div>
   
   <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'90%' } }
        >
         
          <Footer />
        </div> 
      <div
        dangerouslySetInnerHTML={{
          __html: `
      ${useS},
     `,
        }}
      ></div>{" "}
      <div>
        <div className="f1"></div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import "./App.css";


// import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Zone1 from './common/zone1'
import Zone2 from './common/zone2'

import ExpoEntry from './pages/expoentry'
// import Zone1 from "./common/zone1";
import axios from "axios";
 import JllEntryLobby from "./jlle.mp4";
 import LobbyMaiVideo from "./jllm.mp4";
import PhotoEntry from './pages/photoentry'
// import { useEffect, useState } from 'react';
// import { useState } from 'react';
// import Expo from "./common/stall";
import "./tachyons/css/tachyons.min.css";
// import '../tachyons/css/swiper.min.css';
import "./tachyons/css/style.css";
// import Apicall from "./common/apicall";
// import Header from "./common/header";
import Footer from "./common/footer";
import Homepage from "./pages/homepage";
 import Test from "./pages/expohall";
// import Breakout from "./pages/breakout";
import Auditorium from "./pages/auditorium";
import Expo from "./pages/expo";
import Phtotobooth from "./pages/phtotobooth";
// import Gamesarena from "./pages/gamesarena";
// import Speaker from "./pages/speaker";
import { Animated } from "react-animated-css";
// import Card from "./pages/card";
// import Liveaudition from "./pages/liveaudition";
import Resourcecenter from "./pages/resourcecenter";
import { useHistory } from 'react-router-dom';
import Live from './pages/live'
// import { useHistory } from 'react-router-dom';

// import { useLocation } from "react-router-dom";
// import Phtotobooth  from "./pages/phtotobooth";


function App1() {
  return (
    <iframe
      className="vide "
      src="https://player.vimeo.com/video/522670331?autoplay=1&mute=0"
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
    />
  );
}


const Submenu = () => {
  return (
    <ul className="nav__submenu">
      <li className="nav__submenu-item ">
        <a>Our Company</a>
      </li>
      <li className="nav__submenu-item ">
        <a>Our Team</a>
      </li>
      <li className="nav__submenu-item ">
        <a>Our Portfolio</a>
      </li>
    </ul>
  );
};

export default function App(props) {


  const [dt, setDt] = useState(0);
  const [notiNo,setNotiNo]=useState(localStorage.getItem('notino'))
  
 const endpoint="https://sirc.virtuallive.in:3002"
 



//  useEffect(async() => {


//   let timer1 = setTimeout(async() => {
//     let response = await axios.get('https://sirc.virtuallive.in:3002/notificationinfo')
//  console.log(response.data)
 
//   if(response&&response.data&&response.data.status=="true")
//   {
    
//     setNotification(response.data.content)
//     setNotiNo(response.data.notino)
//     let timer2 = setTimeout(async() => {
    
     
//       localStorage.setItem('notino',response.data.notino)
//       setNotification(false)
//       clearTimeout(timer2);
//     },10000)

//   }
//   setDt(dt+1)
//   }, 20000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dt]
// )
 
  const [popUpName, setPopDown] = useState(true);
  const [notification, setNotification] = useState(true);

  // http://localhost:3001/notificationinfo

  const [stateMenu, setstateMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  // const location = useLocation();
  // console.log("===>>>>>>", window.location.pathname);
  return (
    <Router>
      <div className="flex items-center justify-center w-100  ">
    
      {  window.location.pathname !== "/" && <div
          className=" absolute z-max  pointer"
          style={{ top: "-13px", right: 20 }}
          // onClick={() => setShowProfile(true)}
        >
          {/* <img
            className="h2-3 w2-3"
            src={require("../src/image/prof.png").default}
            alt=""
          /> */}

          <nav className="nav  ">
            <ul className="nav__menu" style={{ background: "transparent" }}>
              <li className="nav__menu-item">
                <a className>
                  {" "}
                  <img
                    className="h2-3 w2-3"
                    src={require("../src/image/prof.png").default}
                    alt=""
                  />
                </a>
                <ul className="ba bg-navy bw2 nav__submenu nt3 shadow-4">
                  <li className=" ttu  black pl3 f5 fw6  h2  white flex items-center justify-start w-100 flex  items-center justify-center">
                    {localStorage.getItem("userName")}
                  </li>
                  <li className=" nav__submenu-item black pl3  h2 f5 white fw6  flex items-center justify-start w-100 flex  items-center justify-center ">
                    {localStorage.getItem("userEmail")}
                  </li>
                  <li className="   flex  items-center justify-center nav__submenu-item black pl3  fw6  flex items-center justify-start ">
                    <div  onClick={()=>(localStorage.clear(),window.location.href="/")} className="bg-red mv2 h2 white br2 w-40 white br2 br-pill flex  items-center f7 justify-center">
                      Logout
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>}
        {showProfile && (
          <div
            className="flex w-100 ba    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-30   flex justify-center items-center mh3 br3 overflow-hidden">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex items-end justify-end pointer "
                    onClick={() => setShowProfile(false)}
                  >
                    X
                  </div>
                  <div className=" flex items-center justify-center ">
                    {" "}
                    <img
                      className="h33"
                      src={require("../src/image/prof.png").default}
                      alt=""
                    />
                  </div>
                  <div className=" flex items-center justify-center mv2 fw6  ">
                    {" "}
                    Name :{" "}
                    <span className="fw4">
                      {localStorage.getItem("userName")}
                    </span>
                  </div>
                  <div className=" flex items-center justify-center mv2 fw6  ">
                    {" "}
                    Email :{" "}
                    <span className="fw4">
                      {localStorage.getItem("userEmail")}
                    </span>
                  </div>
                  <div className=" flex items-center justify-center mt2 fw5 pointer bg-red h2 w-50 br-pill  mt2 ">
                    {" "}
                    <span className=" white f6">Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>



      {notification && window.location.pathname !=="/" && localStorage.getItem('notino')!==notiNo && (
        <div className="flex z-max items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-40    flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex white items-end justify-end pointer "
                    onClick={() => (
                      setNotification(false)
                     
                    )}
                  >
                    X
                  </div>
                  <div
                    className="  w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div className="white  tc">
                     {notification}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
{/* window.location.pathName} */}

       {window.location.pathname!=="/"?
        (localStorage.getItem('userEmail')==null?window.location.href="/":'')
        :
        ''
      } 

      <Switch>
      {true  && (
        <Route exact path="/">
          <Homepage />
        </Route>
     )}

<Route exact path="/lobby">
          <LobbyMain />
        </Route>



        {/* <Route exact path="/expo">
          <Test />
        </Route> */}

        <Route exact path="/entrylobby">
          <EmbedVideo />
        </Route>

        <Route exact path="/arenaentry">
          <ExpoEntry />
        </Route>

        <Route exact path="/arena">
          <Expo />
        </Route>
        <Route exact path="/photobooth">
          <Phtotobooth />
        </Route>
        <Route exact path="/photoboothentry">
          <PhotoEntry />
        </Route>
        <Route exact path="/auditorium">
          <Auditorium />
        </Route>
        <Route exact path="/live">
          <Live />
        </Route>
        <Route exact path="/zone1">
          <Zone1 />
        </Route>
        <Route exact path="/zone2">
          <Zone2 />
        </Route>
        {/* <Route exact path="/resource-center">
          <Reac />
        </Route> */}

     
    
          
    
       </Switch>
    </Router>
  );
}
const EmbedVideo = function (props) {
  const [popUpName, setPopDown] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const history = useHistory();


const [counter,setCounter]=useState(0)
  useEffect(async() => {
  
    // let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"lobby",time:10})

   let response2 = await axios.post('https://jllpartnersummit2021.virtuallive.in:3002/lastlogin',
   {userEmail: localStorage.getItem('userEmail'),
   userName: localStorage.getItem('userName'),
 
  
 })
console.log("response",response2)
 
 
 return () => {
 
 };
}
 ,[])



 useEffect( () => {
  const timer = setTimeout(async() => {

    setCounter(counter+1)
    let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"lobby",time:10})
 
  },10000);
  return () => clearTimeout(timer);
}, [counter])





  useEffect(() => {
    const timer = setTimeout(() => {

      window.location.href="/lobby"
   
    },30000);
    return () => clearTimeout(timer);
  }, [])

  const [stateMenu, setstateMenu] = useState(false);
  const [stateMenuAgenda, setstateMenuAgenda] = useState(false);
  const [stateMenuSurvey, setstateMenuSurvey] = useState(false);
  const [surveyShow, setSurveyShow] = useState(false);
  const [surveyRating, setSurveyRating] = useState("");
  const [surveySpeaker, setSurveySpeaker] = useState("");
  const [surveyExperience, setSurveyExperience] = useState("");

  const [surveyContentRating, setSurveyContentRating] = useState("");
  
  const [surveyColleagues, setSurveyColleagues] = useState("");
  
  const [agendaShow, setAgendaShow] = useState(false);
 
  const [surveySocial, setSurveySocial] = useState("");


  const [welcomeMsj, setWelcomeMsj] = useState(
    localStorage.getItem("msjdone") == null ? true : false
  );
  console.log(localStorage.getItem("msjdone") == null ? true : false);
  const [useS, useSet] = useState(`<video
    loop
    autoPlay
    playsinline
    preload
    
    
    src="${JllEntryLobby}"
    class=${window.innerHeight >900 ? 'vide':window.innerHeight > 700?'videSmall':'videSmall' }
  />`);
  return (
    <div>
      {" "}
      {/* <div
        onMouseOut={() => setstateMenuSurvey(false)}
        onMouseOver={() => setstateMenuSurvey(true)}
        onClick={() => setSurveyShow(true)}
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuSurvey ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "40%",
          right: 0,
          width: stateMenuSurvey ? "10%" : "4%",
        }}
      >
        <div className="flex i ml2 " onClick={() => setSurveyShow(true)}>
          {" "}
          <img className="h2-2" src={require("./image/Survey black.png").default} />
        </div>
        {stateMenuSurvey && (
          <div onClick={() => setSurveyShow(true)} className=" ml1 f7">
            SURVEY
          </div>
        )}
      </div> */}
      
      {/* <div
        onMouseOut={() => setstateMenuAgenda(false)}
        onMouseOver={() => setstateMenuAgenda(true)}
        onClick={() => history.push("/attend")}
        className={`
          flex   items-center justify-start absolute ${
            stateMenuAgenda ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "48%",
          right: 0,
          width: stateMenuAgenda ? "10%" : "4%",
        }}
      >
        <div   className="flex i ml2 " onClick={() => setAgendaShow(true)}>
          {" "}
          <img  onClick={() => history.push("/attend")} className="h2-2" src={require("./image/Attendees Black.png").default} />
        </div>
        {stateMenuAgenda && (
          <div  onClick={() => history.push("/attend")} className="f7">
            Attendees
          </div>
        )}
      </div> */}

      <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'89%' } }
        >
         
          <Footer />
        </div> 

     
       
        {/* <div
          onMouseOut={() => setstateMenu(false)}
          onMouseOver={() => setstateMenu(true)}
          onClick={() => history.push("/leaderboard")}
          className={`
          flex   items-center justify-center absolute ${
            stateMenu ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
          style={{ top: "56%", right: 0, width: stateMenu ? "14%" : "4%" }}
        >
          <div onClick={() => history.push("/leaderboard")}>
        
            <img onClick={() => history.push("/leaderboard")} className="h2-4" src={require("./image/lb.png").default} />
          </div>
          {stateMenu && <div className="ml1 f7" onClick={() => history.push("/leaderboard")}> Leader Board</div>}
        </div> */}
     
      <div
      
        dangerouslySetInnerHTML={{
          __html: `
      ${useS},
     `,
        }}
      ></div>{" "}
      {welcomeMsj && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-40    flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex white items-end justify-end pointer "
                    onClick={() => (
                      setWelcomeMsj(false),
                      localStorage.setItem("msjdone", "true")
                    )}
                  >
                    X
                  </div>
                  <div
                    className="  w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div className="black flex flex-column tc">
                <div>Welcome to JLL Partner Summit 2021</div>     <div className="ttu tc  lh-copy mt2">{localStorage.getItem("userName")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {agendaShow && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-60   flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex  pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex items-end justify-end pointer "
                    onClick={() => setAgendaShow(false)}
                  >
                    X
                  </div>
                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../src/image/agenda.png").default}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {surveyShow && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-60    flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white br3">
                <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 br3 ">
                  <div
                    className=" w-100 flex f6 fw6  items-end white justify-end pointer "
                    onClick={() => setSurveyShow(false)}
                  >
                    X
                  </div>
                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                
                      <div id="myDiv" className="img-wrapper">
                  <div
                    className=" w-100 flex flex-column items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div className="flex">
                      <h1 className="white mv2 bg-blue">
                        VITTHAKAM - 53rd Regional Conference of SIRC of ICAI{" "}
                      </h1>
                    </div>
                    <div className="flex mt2 flex-column items-center justify-center w-70">


                      <div className="flex mv2  w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                          Overall Rating *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyRating("1")}
                                type="checkbox"
                                checked={surveyRating=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("2")}
                                type="checkbox"
                                checked={surveyRating=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("3")}
                                type="checkbox"
                                checked={surveyRating=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("4")}
                                type="checkbox"
                                checked={surveyRating=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("5")}
                                type="checkbox"
                                checked={surveyRating=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>


                      {/* 2nd */}
 
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate the Content? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveySpeaker("1")}
                                type="checkbox"
                                checked={surveySpeaker=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("2")}
                                type="checkbox"
                                checked={surveySpeaker=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("3")}
                                type="checkbox"
                                checked={surveySpeaker=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("4")}
                                type="checkbox"
                                checked={surveySpeaker=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("5")}
                                type="checkbox"
                                checked={surveySpeaker=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>
                      {/* 3rd */}



                      <div className="flex mv2  w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate the Speakers? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyContentRating("1")}
                                type="checkbox"
                                checked={surveyContentRating=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("2")}
                                type="checkbox"
                                checked={surveyContentRating=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("3")}
                                type="checkbox"
                                checked={surveyContentRating=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("4")}
                                type="checkbox"
                                checked={surveyContentRating=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("5")}
                                type="checkbox"
                                checked={surveyContentRating=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>

                      {/* 4th */}


                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate your overall experience here today? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyExperience("1")}
                                type="checkbox"
                                checked={surveyExperience=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("2")}
                                type="checkbox"
                                checked={surveyExperience=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("3")}
                                type="checkbox"
                                checked={surveyExperience=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("4")}
                                type="checkbox"
                                checked={surveyExperience=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("5")}
                                type="checkbox"
                                checked={surveyExperience=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>

                      {/* 5th */}
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How likely are you to recommend our future events to friends & colleagues?
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyColleagues("1")}
                                type="checkbox"
                                checked={surveyColleagues=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("2")}
                                type="checkbox"
                                checked={surveyColleagues=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("3")}
                                type="checkbox"
                                checked={surveyColleagues=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("4")}
                                type="checkbox"
                                checked={surveyColleagues=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("5")}
                                type="checkbox"
                                checked={surveyColleagues=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>
                      {/* 6th */}
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How did you come to know about this summit? *
                        </div>
                        <div className="w-100 mt2 flex  items-center justify-start">
                         
                          <div className="flex w-100  ml3 items-center justify-start">
                           

                            <div className="flex   items-center justify-start">
                              {" "}
                              <input
                                onClick={() => setSurveySocial("email")}
                                type="checkbox"
                                checked={surveySocial=="email"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                            <div className="flex ml1  items-center justify-start fw5 white">Email</div>
                          </div>
                          <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("sirc")}
                               type="checkbox"
                               checked={surveySocial=="sirc"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">SIRC Website</div>
                         </div>


                         <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("socialMedia")}
                               type="checkbox"
                               checked={surveySocial=="socialMedia"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">Social Media</div>
                         </div>
                         <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("Reference")}
                               type="checkbox"
                               checked={surveySocial=="Reference"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">Reference</div>
                         </div>
                        
                        

                        </div>
                      </div>



                   

                      <div className="flex flex-column mv2 flex w-100 items-center justify-center ">
                      <div className="flex mv2 white f5 w-100  ml3 items-center justify-start">
                      Remarks / Any other Suggestion(s) (Optional)</div> 
                        <textarea
                          className="pa1 w-100 mt2 flex w-60"
                          type="text"
                          placeholder="Your Remarks"
                        />
                      </div>
                      <div className="w-60 pa2 br2  btn flex items-center justify-center  pointer mv2">
                        <Animated
                          animationIn="bounceInLeft"
                          animationOut="fadeIn"
                          isVisible={true}
                        >
                          <div className=" fw6 f7  white">SUBMIT</div>
                        </Animated>
                      </div>
                    </div>
                  </div>
</div>
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="f1"></div>
      </div>
    </div>
  );
}


const LobbyMain = function (props) {
  const [checkSubmitForm, setCheckSubmitForm] = useState(true);
  const [counter,setCounter]= useState(0);
  useEffect(async() => {
  
    // let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"lobby",time:10})

   let response2 = await axios.post('https://jllpartnersummit2021.virtuallive.in:3002/lastlogin',
   {userEmail: localStorage.getItem('userEmail'),
   userName: localStorage.getItem('userName'),
 
  
 })
console.log("response",response2)
 
 
 return () => {
 
 };
}
 ,[])



 useEffect( () => {
  const timer = setTimeout(async() => {

    setCounter(counter+1)
    let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"lobby",time:10})
 
  },10000);
  return () => clearTimeout(timer);
}, [counter])


  useEffect(() => {
    const script = document.createElement("script");

    script.src = 'https://embed.tawk.to/60dacd3f65b7290ac63871e4/1f9bb3gu7';
    script.async = true;

    document.body.appendChild(script);

    return () => {
        // alert(6)
      document.body.removeChild(script);
    };
  }, []);

  const [stateMenu, setstateMenu] = useState(false);
  const [stateMenuAgenda, setstateMenuAgenda] = useState(false);
  const [stateMenuSurvey, setstateMenuSurvey] = useState(false);
  const [surveyShow, setSurveyShow] = useState(false);
  const [surveyRating, setSurveyRating] = useState("");
  const [surveySpeaker, setSurveySpeaker] = useState("");
  const [surveyExperience, setSurveyExperience] = useState("");

  const [surveyContentRating, setSurveyContentRating] = useState("");
  
  const [surveyColleagues, setSurveyColleagues] = useState("");
  
  const [agendaShow, setAgendaShow] = useState(false);
 
  const [surveySocial, setSurveySocial] = useState("");
  const [suggestion, setSuggestion] = useState("");

  

  const history = useHistory();
  const [welcomeMsj, setWelcomeMsj] = useState(
    localStorage.getItem("msjdone") == null ? true : false
  );



  const SubmitForm=async()=>{
  
    let response = await axios.post(`https://sirc.virtuallive.in:3002/formsssssurvey`, {
      userEmail: localStorage.getItem('userEmail'),

      userName: localStorage.getItem('userName'),
      ques1:surveyRating,
      ques2:surveyContentRating,
      ques3:surveySpeaker,
      ques4:surveyExperience,
      ques5:surveyColleagues,
      ques6:surveySocial,
      ques7:suggestion





    
    
    
    })
    console.log("response",response)

    if(response.status==200)
   
{
  setCheckSubmitForm(false)
}     
  }
    
     
     
  

  console.log(localStorage.getItem("msjdone") == null ? true : false);
  const [useS, useSet] = useState(`<video
    loop
    autoPlay
    playsinline
    preload
    
    
    src=${LobbyMaiVideo}
    class=${window.innerHeight >900 ? 'vide':window.innerHeight > 700?'videSmall':'videSmall' }
  />`);
  return (
    <div>
      {" "}
      {/* <div
        onMouseOut={() => setstateMenuSurvey(false)}
        onMouseOver={() => setstateMenuSurvey(true)}
        onClick={() => setSurveyShow(true)}
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuSurvey ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "40%",
          right: 0,
          width: stateMenuSurvey ? "10%" : "4%",
        }}
      >
        <div className="flex i ml2 " onClick={() => setSurveyShow(true)}>
          {" "}
          <img className="h2-2" src={require("./image/Survey black.png").default} />
        </div>
        {stateMenuSurvey && (
          <div onClick={() => setSurveyShow(true)} className=" ml1 f7">
            SURVEY
          </div>
        )}
      </div>
       */}
      {/* <div
        onMouseOut={() => setstateMenuAgenda(false)}
        onMouseOver={() => setstateMenuAgenda(true)}
        onClick={() => history.push("/attend")}
        className={`
          flex   items-center justify-start absolute ${
            stateMenuAgenda ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "48%",
          right: 0,
          width: stateMenuAgenda ? "10%" : "4%",
        }}
      >
        <div   className="flex i ml2 " onClick={() => setAgendaShow(true)}>
          {" "}
          <img  onClick={() => history.push("/attend")} className="h2-2" src={require("./image/Attendees Black.png").default} />
        </div>
        {stateMenuAgenda && (
          <div  onClick={() => history.push("/attend")} className="f7">
            Attendees
          </div>
        )}
      </div> */}

      <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'89%' } }
        >
         
          <Footer />
        </div> 

     
       {/* {best('https://embed.tawk.to/5f5224d9f0e7167d000d6d31/default')} */}
        {/* <div
          onMouseOut={() => setstateMenu(false)}
          onMouseOver={() => setstateMenu(true)}
          onClick={() => history.push("/leaderboard")}
          className={`
          flex   items-center justify-center absolute ${
            stateMenu ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
          style={{ top: "56%", right: 0, width: stateMenu ? "14%" : "4%" }}
        >
          <div onClick={() => history.push("/leaderboard")}>
        
            <img onClick={() => history.push("/leaderboard")} className="h2-4" src={require("./image/lb.png").default} />
          </div>
          {stateMenu && <div className="ml1 f7" onClick={() => history.push("/leaderboard")}> Leader Board</div>}
        </div> */}
     
      <div
      
        dangerouslySetInnerHTML={{
          __html: `
      ${useS},
     `,
        }}
      ></div>{" "}
      {/* {welcomeMsj && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-50    flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex white items-end justify-end pointer "
                    onClick={() => (
                      setWelcomeMsj(false),
                      localStorage.setItem("msjdone", "true")
                    )}
                  >
                    X
                  </div>
                  <div
                    className="  w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div className="white  tc">
                    Welcome to  VITTHAKAM - 53rd Regional Conference  Of SIRC OF ICAI <p className="ttu tc  lh-copy mt2">{localStorage.getItem("userName")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {agendaShow && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-60   flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex  pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex items-end justify-end pointer "
                    onClick={() => setAgendaShow(false)}
                  >
                    X
                  </div>
                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../src/image/agenda.png").default}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {surveyShow && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-60    flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white br3">
                <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 br3 ">
                  <div
                    className=" w-100 flex f6 fw6  items-end white justify-end pointer "
                    onClick={() => setSurveyShow(false)}
                  >
                    X
                  </div>
                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                
                      <div id="myDiv" className="img-wrapper w-100 flex flex-column items-center justify-center">
                 {checkSubmitForm ? <div
                    className=" w-100 flex flex-column items-center justify-start "
                    style={{ overflow: "scroll" }}
                  >
                    <div className="flex">
                      <h1 className="white mv2 bg-blue">
                        VITTHAKAM - 53rd Regional Conference of SIRC of ICAI{" "}
                      </h1>
                    </div>
                    <div className="flex mt2 flex-column items-center justify-center w-70">


                      <div className="flex mv2  w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                          Overall Rating *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyRating("1")}
                                type="checkbox"
                                checked={surveyRating=="1"?true:false}
                                id="topping"
                                name="topping"
                                // value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("2")}
                                type="checkbox"
                                checked={surveyRating=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("3")}
                                type="checkbox"
                                checked={surveyRating=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("4")}
                                type="checkbox"
                                checked={surveyRating=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyRating("5")}
                                type="checkbox"
                                checked={surveyRating=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>


                      {/* 2nd */}
 
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate the Content? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveySpeaker("1")}
                                type="checkbox"
                                checked={surveySpeaker=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("2")}
                                type="checkbox"
                                checked={surveySpeaker=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("3")}
                                type="checkbox"
                                checked={surveySpeaker=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("4")}
                                type="checkbox"
                                checked={surveySpeaker=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveySpeaker("5")}
                                type="checkbox"
                                checked={surveySpeaker=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>
                      {/* 3rd */}



                      <div className="flex mv2  w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate the Speakers? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyContentRating("1")}
                                type="checkbox"
                                checked={surveyContentRating=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("2")}
                                type="checkbox"
                                checked={surveyContentRating=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("3")}
                                type="checkbox"
                                checked={surveyContentRating=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("4")}
                                type="checkbox"
                                checked={surveyContentRating=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyContentRating("5")}
                                type="checkbox"
                                checked={surveyContentRating=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>

                      {/* 4th */}


                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How would you rate your overall experience here today? *
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyExperience("1")}
                                type="checkbox"
                                checked={surveyExperience=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("2")}
                                type="checkbox"
                                checked={surveyExperience=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("3")}
                                type="checkbox"
                                checked={surveyExperience=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("4")}
                                type="checkbox"
                                checked={surveyExperience=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyExperience("5")}
                                type="checkbox"
                                checked={surveyExperience=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>

                      {/* 5th */}
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How likely are you to recommend our future events to friends & colleagues?
                        </div>
                        <div className="w-100 mt2 flex items-center justify-center">
                          <div className="flex w-20 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white f7">
                              {" "}
                              Needs improvement
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">1</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                onClick={() => setSurveyColleagues("1")}
                                type="checkbox"
                                checked={surveyColleagues=="1"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex w-15 flex-column  items-center justify-center">
                            <div className="flex  items-center justify-center fw5 white">2</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("2")}
                                type="checkbox"
                                checked={surveyColleagues=="2"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex   items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white ">3</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("3")}
                                type="checkbox"
                                checked={surveyColleagues=="3"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">4</div>

                            <div className="flex  items-center justify-center">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("4")}
                                type="checkbox"
                                checked={surveyColleagues=="4"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>
                          <div className="flex  items-center justify-center w-15 flex-column">
                            <div className="flex  items-center justify-center fw5 white">5</div>

                            <div className="flex  items-center justify-center ">
                              {" "}
                              <input
                                 onClick={() => setSurveyColleagues("5")}
                                type="checkbox"
                                checked={surveyColleagues=="5"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                          </div>

                          <div className="flex  w-10 mh2 flex-column">
                            <div className="flex fw5 white"></div>

                            <div className="flex white fw6 f7"> Excellent</div>
                          </div>
                        </div>
                      </div>
                      {/* 6th */}
                      <div className="flex  mv2 w-100 flex-column mv2 flex w-100 items-center justify-center ">
                        <div className="w-100 flex items-center justify-start fw5 white ">
                        How did you come to know about this summit? *
                        </div>
                        <div className="w-100 mt2 flex  items-center justify-start">
                         
                          <div className="flex w-100  ml3 items-center justify-start">
                           

                            <div className="flex   items-center justify-start">
                              {" "}
                              <input
                                onClick={() => setSurveySocial("email")}
                                type="checkbox"
                                checked={surveySocial=="email"?true:false}
                                id="topping"
                                name="topping"
                                value="Paneer"
                              />
                            </div>
                            <div className="flex ml1  items-center justify-start fw5 white">Email</div>
                          </div>
                          <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("sirc")}
                               type="checkbox"
                               checked={surveySocial=="sirc"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">SIRC Website</div>
                         </div>


                         <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("socialMedia")}
                               type="checkbox"
                               checked={surveySocial=="socialMedia"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">Social Media</div>
                         </div>
                         <div className="flex w-100  ml3 items-center justify-start">
                           

                           <div className="flex   items-center justify-start">
                             {" "}
                             <input
                               onClick={() => setSurveySocial("Reference")}
                               type="checkbox"
                               checked={surveySocial=="Reference"?true:false}
                               id="topping"
                               name="topping"
                               value="Paneer"
                             />
                           </div>
                           <div className="flex ml1  items-center justify-start fw5 white">Reference</div>
                         </div>
                        
                        

                        </div>
                      </div>



                   

                      <div className="flex flex-column mv2 flex w-100 items-center justify-center ">
                      <div className="flex mv2 white f5 w-100  ml3 items-center justify-start">
                      Remarks / Any other Suggestion(s) (Optional)</div> 
                        <textarea
                          className="pa1 w-100 mt2 flex w-60"
                          type="text"
                          onChange={(e)=>setSuggestion(e.target.value)}
                          placeholder="Your Remarks"
                        />
                      </div>
                      <div className="w-60 pa2 br2  btn flex items-center justify-center  pointer mv2">
                        <Animated
                          animationIn="bounceInLeft"
                          animationOut="fadeIn"
                          isVisible={true}
                        >
                          <div onClick={()=>SubmitForm()} className="w-100 fw6 f7  white">SUBMIT</div>
                        </Animated>
                      </div>
                    </div>
                  </div>:<div className="flex w-100 items-center f3 justify-center white">
                    Thanks for your feedback</div>}
</div>
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="f1"></div>
      </div>
    </div>
  );
}
function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

import React, { useEffect,useState } from "react";
import ZoneName from "../common/zoneName";
import Footer from "../common/footer";
import Back from '../image/back.jpg'
import axios from 'axios'
export default function Test() {



  const [counter,setCounter]= useState(0);
 


 useEffect( () => {
  const timer = setTimeout(async() => {

    setCounter(counter+1)
    let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"arena",time:10})
 
  },10000);
  return () => clearTimeout(timer);
}, [counter])
 
 

//   useEffect(() => {
//     const script = document.createElement("script");

//     script.src = "https://embed.tawk.to/5f5b4c924704467e89ee1498/default";
//     script.async = true;

//     document.body.appendChild(script);

//     return () => {
//         // alert(6)
//       document.body.removeChild(script);
//     };
//   }, []);


// 

// CPA Australia  
// Indus Novateur Softech Private Limited 
// Effitrac Solutions India Pvt Ltd
// Accopedia School of Accounting & Finance 
// Greytip Software Pvt. Ltd. 
// Karur Vysya Bank 
// Bajaj Allianz 
// Tamilnad Mercantile Bank 
// Kovai Medical Center and Hospital 
// SAIF Zone 
// Milky Mist  
// Zoho Corporation

// 
 
 
  return (
    <div className=" " style={{
      backgroundImage: `url(${Back})`,
      backgroundSize: "cover",
      height: "100vh",

      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    }}>
      {/* {console.log("sss", window.innerHeight)} */}
      {/* <div className="w-100 h-100">
        <img src={require("../image/back.jpg").default} class="bg" />
      </div> */}
      <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'88%' } }
        >
         
          <Footer />
        </div> 
    



<ZoneName
        path="/zone1"
        height= {window.innerHeight >900 ? '12rem':window.innerHeight > 700?"10rem":"10rem" }
       
        width= {window.innerHeight >900 ? '18rem':window.innerHeight > 700?"15rem":"14rem" }
        fontSize="16px"
        name=" Schevaran "
        top= {window.innerHeight >900 ? '58%':window.innerHeight > 700?'60%':'60%' }
        left="24%"
      />




<ZoneName
        path="/zone2"
        height= {window.innerHeight >900 ? '12rem':window.innerHeight > 700?"10rem":"10rem" }
        width= {window.innerHeight >900 ? '18rem':window.innerHeight > 700?"15rem":"14rem" }
        fontSize="16px"
        name="Origami Cellulo Pvt Ltd"
        top= {window.innerHeight >900 ? '58%':window.innerHeight > 700?'60%':'60%' }
        left="63%"
      />


     
    

      {/* 2nd row */}
     
   
     
   

      {/* bottom zone */}
     
     
    
      
    </div>
  );
}

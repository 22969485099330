import React, { useState, useEffect } from "react";
//  import Background from "../image/stall1.jpg";
import StallBg from "../image/stall1.jpg";
import axios from "axios";

import Pdf from "../pdf/zone1/Cleanfix Schevaran.pdf";
import Pdf2 from "../pdf/zone1/filmop_catalogo_generale_2020_en_cod-810_low.pdf";
import Pdf3 from "../pdf/zone1/Schevaran.pdf";
import Footer from "./footer";
import Bcard from "../image/bcard.jpg";

// import Video1 from "../video.mp4";
import html2canvas from "html2canvas";

export default function Zone1(props) {
  // useState
  const [emailSend, setEmailSend] = useState("");




  const [counter,setCounter]= useState(0);




  useEffect( () => {
   const timer = setTimeout(async() => {
 
     setCounter(counter+1)
     let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"zone1",time:10})
  
   },10000);
   return () => clearTimeout(timer);
 }, [counter])
  console.log("Sss", props);
  //on load
  // useEffect(async () => {
  //   let response = await axios.post(
  //     `https://sirc.virtuallive.in:3002/leaderboard`,
  //     {
  //       userEmail: localStorage.getItem("userEmail"),
  //       zoneView: "zone1",
  //       userName: localStorage.getItem("userName"),
  //     }
  //   );
  //   let response2 = await axios.post(
  //     "https://sirc.virtuallive.in:3002/lastlogin",
  //     {
  //       userEmail: localStorage.getItem("userEmail"),
  //       userName: localStorage.getItem("userName"),
  //       userMemberid: localStorage.getItem("userMemberId"),
  //       userPhoneNumber: localStorage.getItem("userPhoneNumber"),
  //     }
  //   );
  //   console.log("response", response, response2);

  //   return () => {};
  // }, []);

  // after 15000
  // const [dataScore2, setScoreData2] = useState(0);
  // useEffect(async () => {
  //   let timer1 = setTimeout(async () => {
  //     let response = await axios.post(
  //       "https://sirc.virtuallive.in:3002/timespentrecord",
  //       {
  //         userEmail: localStorage.getItem("userEmail"),
  //         pageid: "lobby",
  //         time: 5,
  //       }
  //     );

  //     setScoreData2(dataScore2 + 1);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // }, [dataScore2]);

  // for last login
  // const [lastlogin, setLastlogin] = useState(0);
  // useEffect(async () => {
  //   let timer1 = setTimeout(async () => {
  //     let response = await axios.post(
  //       "https://sirc.virtuallive.in:3002/lastlogin",
  //       {
  //         userEmail: localStorage.getItem("userEmail"),
  //         userName: localStorage.getItem("userName"),
  //         userMemberid: localStorage.getItem("userMemberId"),
  //         userPhoneNumber: localStorage.getItem("userPhoneNumber"),
  //       }
  //     );

  //     setLastlogin(dataScore2 + 1);
  //   }, 15000);

  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // }, [lastlogin]);

  // //

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://embed.tawk.to/5f521365f0e7167d000d67fc/default";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  const [share, setShareData] = useState("");
  const [shareEmail, setShareEmail] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [imgurl, setImgUrl] = useState();
  const [stateMenuSurvey, setstateMenuSurvey] = useState(false);
  const [stateMenuWeb, setstateMenuWeb] = useState(false);

  const [surveyShow, setSurveyShow] = useState(false);

  const [stateMenu, setstateMenu] = useState(false);
  //     const [useS,useSet]=useState(`<video
  //     loop
  //     autoPlay
  //     playsinline
  //     preload

  //     src="${Video1}"
  //     class="vide"
  //   />`)

  const ShareAsPicture = async (id) => {
    setShareEmail(false);
  };

  // const sendEmail = async (id) => {
  //   let response2 = await axios.post(
  //     "https://sirc.virtuallive.in:3002/mailswnd",
  //     {
  //       userEmail: emailSend,
  //       userEmailBy: localStorage.getItem("userEmail"),
  //       userName: localStorage.getItem("userName"),
  //       userMemberid: localStorage.getItem("userMemberId"),
  //       userPhoneNumber: localStorage.getItem("userPhoneNumber"),
  //     }
  //   );
  //   console.log(
  //     "response2${req.body.userEmail}${req.body.userEmail}",
  //     response2.data
  //   );

  //   if (response2.data.data == "done") {
  //     alert("your email has sent");
  //     setEmailSend("");
  //   }
  // };

  const exportAsPicture = (id) => {
    var html = document.getElementsByTagName("HTML")[0];
    var body = document.getElementsByTagName("BODY")[0];
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;

    var data = document.getElementById(`${id}`);
    var newWidth = data.scrollWidth - data.clientWidth;

    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    console.log("hhhss", htmlWidth, bodyWidth);
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    html2canvas(data)
      .then((canvas) => {
        var image = canvas.toDataURL("image/png", 1);
        return image;
      })
      .then((image) => {
        saveAs(image, "vis.png");
        html.style.width = null;
        body.style.width = null;
      });
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    // elem.style = 'height:600px;width:600px';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${StallBg})`,
        backgroundSize: "cover",
        height: "100vh",
        widh:'100%',

        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >


{
        <div
          className="  absolute z-max  pointer"
          style={{ top: window.innerHeight >900 ? '44%':window.innerHeight > 700?'44%':'44%',
          right: window.innerHeight >900 ? '23.2%':window.innerHeight > 700?'23.2%':'23.2%',
           width: "50px" }}
        
        >
          <nav className="nav w-100 ">
            <ul className="nav__menu" style={{ background: "transparent" }}>
              <li className="nav__menu-item ">
                <a className>
                  {" "}
                  <img
                    className="h2-3 w2-3"
                    // src={require("../image/prof.png").default}
                    src={require("../image/doc.gif").default}
                    alt=""
                  />
                </a>
                <ul
                  className=" backgroundJll  bw2 nav__submenu nt3 shadow-4 "
                  style={{ left: "0%" }}
                >
                  <li
                    className="  black pl3 f5 fw6  flex items-center white justify-start w-100 flex  items-center justify-start"
                    tyle={{ padding: "0px", margin: "0px" }}
                  >
                    <a
                      href={Pdf}
                      className="white f6 "
                      target="_blank"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                     Cleanfix Schevaran
                    </a>
                  </li>
                  <li
                    className="nav__submenu-item black pl3 f5 fw6  white flex items-center justify-start w-100 flex  items-center justify-start "
                    tyle={{ padding: "0px", margin: "0px" }}
                  >
                    <a
                      className="white f6"
                      href={Pdf2}
                      target="_blank"
                      tyle={{ padding: "0px", margin: "0px" }}
                    >
                      Filmop
                    </a>
                  </li>
                  <li className="nav__submenu-item black pl3 f5 fw6  white flex items-center justify-start w-100 flex  items-center justify-start ">
                    <a className="white f6" href={Pdf3} target="_blank">
                    Schevaran
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      }






      {/* <div
          onMouseOut={() => setstateMenuSurvey(false)}
          onMouseOver={() => setstateMenuSurvey(true)}
          onClick={() => setShowPopUp(true)}
          className={`
          flex   items-center  justify-start absolute ${
            stateMenuSurvey ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
          style={{
            top: "40%",
            right: 0,
            width: stateMenuSurvey ? "10%" : "4%",
          }}
        >
          <div className="flex pa1 ml2 " onClick={() => setShowPopUp(true)}>
            {" "}
            <img
              className="h2 w2"
              src={require("../image/Businesscard.png").default}
              alt=""
            />
          </div>
          {stateMenuSurvey && (
            <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
              Business card
            </div>
          )}
        </div> */}

      <a
        href={
         "https://schevaran.com"
        }
        className="white"
        target="_blank"
      >
        <div
          onMouseOut={() => setstateMenuWeb(false)}
          onMouseOver={() => setstateMenuWeb(true)}
          className={`
          flex   items-center  justify-start absolute ${
            stateMenuWeb ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
          style={{
            top: "48%",
            right: 0,

            width: stateMenuWeb ? "10%" : "4%",
          }}
        >
          <div className="flex pa1 ml2 " onClick={() => setShowPopUp(true)}>
            {" "}
            <img
              className="h2 w2"
              src={require("../image/website.png").default}
              alt=""
            />
          </div>
          {stateMenuWeb && (
            <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
              Website
            </div>
          )}
        </div>
      </a>

      <div
        className="flex items-center   justify-center w-100"
        style={{
          position: "absolute",
          top:
            window.innerHeight > 900
              ? "90%"
              : window.innerHeight > 700
              ? "90%"
              : "98%",
        }}
      >
        <Footer />
      </div>

      <div>
        <iframe
          style={{
            position: "absolute",
            top:
              window.innerHeight > 900
                ? "7%"
                : window.innerHeight > 700
                ? "6%"
                : "6%",
            left:
              window.innerHeight > 900
                ? "42%"
                : window.innerHeight > 700
                ? "42%"
                : "42%",
            height: "100%",
            width:
              window.innerHeight > 900
                ? "21%"
                : window.innerHeight > 700
                ? "21%"
                : "21%",
          }}
          class=" "
          src="https://player.vimeo.com/video/648543700"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>{" "}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import ForgotPassword from "../common/forgot";
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";
import Datajson from "../data.json";
import { Redirect } from "react-router-dom";
import axios from "axios";
export default function Homepage() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameEdit, setFullNameEdit] = useState(true);
  const [loginStatusCode, setLoginStatusCode] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [memberIdError, setMemberIdError] = useState("");

  const [memberId, setMemberId] = useState("");
  const [showPass, setPassShow] = useState("");
  const [showPass1, setPassShow1] = useState("");

  const [loginPass, setLoginPass] = useState("");
  const [loginEmail, setLoginEmail] = useState("");

  const [showConfPass, setConfPassShow] = useState("");
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [alreadyerror, showAlreadyError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const history = useHistory();
  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
  const Phoncheck = /^[0-9\b]+$/;
  var passwordCheck =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  var reEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  function emailCheck() {
    console.log("cvbnm====>>>>>>>>>>", reEmail.test(email));
  }

  const handleRegistration = async () => {
    setShowSuccess(false);
    showAlreadyError(false);

    let response = await axios.post(
      'http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/findoneuser'
      ,
      {
        userPassword: password,
        userEmail: email,
        userName: fullName,
      }
    );
    console.log("=>>>>>>>>", response.data);

    if (response.data.statusMsj) {
      showAlreadyError(true);
      setUserName("");
      setFullName("");
      setFullNameEdit(true);
      setEmail("");
      setCnfPassword("");
      setPassword("");
    }

    if (response.data._id) {
      setShowSuccess(true);
      setFullNameEdit(true);
      setUserName("");
      setEmail("");
      setFullName("");
      setCnfPassword("");
      setPassword("");
    }
  };
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");

    script.src = 'https://embed.tawk.to/60dacd3f65b7290ac63871e4/1f9bb3gu7';
    script.async = true;

    document.body.appendChild(script);

    return () => {
        // alert(6)
      document.body.removeChild(script);
    };
  }, []);


  const handleLogin = async () => {
  
    // const dat2=Datajson.Sheet2.find( ({ memid }) => memid === memberId )

    // const dat2 = Datajson.Sheet2.find(({ memid }) => memid === memberId);

  

    let response = await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/findoneuser', {
      userEmail: loginEmail,
      userPassword:loginPass,
    });

    // console.log("12345678=>>>>>>>>", dat2);
    // console.log("=>>>>>>>>",response.data)

    if (response.status == 200) {
      localStorage.setItem("userEmail", response.data.userEmail);
   
      localStorage.setItem("userName", response.data.userName);
    
     
      window.location.href = "/entrylobby";
     
    } else {
     
      setLoginStatusCode(response.data.data);
  
    }
  }

  //  alert(window.innerHeight)
  const handleChange = (value) => {
    if (value === "" || Phoncheck.test(value)) {
      setMemberId(value);

      setMemberIdError("");
    } else {
      setMemberIdError("");
    }
  }

 

  const passwordError =
    "Must contain at least one number, one uppercase and lowercase letter, one special character, and Min 8 or more characters.";
  const cnfPassError = "Password did not match";
  const emailError = "Enter valid Email Id ";
  const nameError = "Enter Vaild Name";
  // console.log("cvbnm====>>>>>>>>>>",  reEmail.test(email), email !== "");
  return (
    <div
      className={`pa3  min-vh-100 w-100 flex flex-column items-end justify-center ${
        window.innerHeight > 900
          ? "backgroundLoginBigcss"
          : window.innerHeight > 700
          ? "backgroundLoginBigcss"
          : "backgroundLoginBigcss"
      }`}
    >
      {!forgotPassword ? (
        <div
          className={`w-95  flex ${
            window.innerHeight > 900
              ? "mt5"
              : window.innerHeight > 700
              ? ""
              : "nt4"
          }`}
        >
          <div className="flex w-75-l mt6 dn db-l"></div>
          <div className="w-25-l  w-90   flex items-end justify-end">
            {/* <div className="flex w-100 navy fw6 ">Login</div> */}
            <div className="flex w-100 flex-column mt6">
              <div className="flex flex-column  w-100 mt1 ">
                <div className="flex flex-column w-100">
                  <input
                  
                    className="w-100 mt2 pa1 "
                    type="text"
                  value={loginEmail}
                    onChange={(e) => (setLoginEmail(e.target.value),setLoginStatusCode("") )}
                  
                    placeholder="Enter Email id "
                  />
                  <p className="red fw6 f5 lh-copy">
                    {reEmail.test(loginEmail) || loginEmail == ""
                      ? ""
                      : emailError}
                  </p>
                </div>
{/* sssss */}
                <div className="flex flex-column w-100">
                <input
                 
                  value={loginPass}
                  onChange={(e) => (setLoginStatusCode(""), setLoginPass(e.target.value))}
                  className="w-100 99 mt2 pa1"
                  type={`${showPass1 ? "text" : "password"}`}
                  placeholder="Enter Password "
                />
               
                {!showPass1 ? (
                  <i
                    class="fas fa-eye-slash tl mt3  pointer right-2-l right-35"
                    onClick={() => setPassShow1(true)}
                    style={{ position: "fixed" }}
                  ></i>
                ) : (
                  <i
                    class="fas fa-eye tl mt3  pointer right-2-l right-35"
                    onClick={() => setPassShow1(false)}
                    style={{ position: "fixed" }}
                  ></i>
                )}
              </div>
              {/* b */}
                
                {memberIdError && (
                  <div className="flex f7 mt2   mb2  ttu red fw8 items-center justify-center w-100 pointer">
                    {memberIdError}
                  </div>
                )}
                <div className="flex">
                  <input type="checkbox" />{" "}
                  <p className="n ml2 mt1">Remember me</p>{" "}
                </div>
                {/* <div className="flex f7  mt2 mb2  ttu red fw8 items-end justify-end w-100 pointer" onClick={()=>setForgotPassword(true)}>
                forgot password
              </div> */}

                <div className="w-100 flex items-center justify-center">
                  <button
                    onClick={() =>
                      (reEmail.test(loginEmail)&&loginPass)?handleLogin():null
                    }
                    style={{
                      opacity: ( reEmail.test(loginEmail) &&loginPass) ? 1 : 0.6,
                    }}
                    className="w-70 pa2 br2 bg-red mt2  borderZero white pointer"
                  >
                    Login
                  </button>
                </div>
                <div className="mt2  fw6 flex items-center justify-center red">
                  {loginStatusCode !== "" && loginStatusCode}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ForgotPassword email={loginEmail} />
      )}
      <div className="w-95 flex flex   ">
        <div className="flex w-75-l dn db-l  "> </div>
        <div className="flex  flex-column  w-25-l w-90 mt5-l  ">
          <div className="flex w-100 fw6 ">Registration</div>
          <div className="flex w-100 flex-column">
            <div className="flex flex-column  w-100 mt1 ">
              <div className="flex flex-column w-100">
                <input
                  value="sss"
                  onChange={(e) => (
                    setEmail(e.target.value),
                    setShowSuccess(false),
                    showAlreadyError(false)
                  )}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={email}
                  placeholder="Enter Email Id "
                />
                <p className="red fw6 f5 lh-copy">
                  {reEmail.test(email) || email == "" ? "" : emailError}
                </p>
              </div>
              <div className="flex flex-column w-100">
                <input
                  value={fullName}
                  onChange={(e) => (
                    setFullName(e.target.value), setFullNameEdit(false)
                  )}
                  className="w-100 mt2 pa1"
                  type="text"
                  placeholder="Enter Full Name"
                  required=""
                />
                <p className="red fw6 f5 lh-copy">
                  {fullName || fullNameEdit ? "" : nameError}
                </p>
              </div>

              <div className="flex flex-column w-100">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-100 99 mt2 pa1"
                  type={`${showPass ? "text" : "password"}`}
                  placeholder="Enter Password "
                />
                <p className="red fw6 f5 lh-copy">
                  {passwordCheck.test(password) || password == ""
                    ? ""
                    : passwordError}
                </p>
                {!showPass ? (
                  <i
                    class="fas fa-eye-slash tl mt3  pointer right-2-l right-35"
                    onClick={() => setPassShow(true)}
                    style={{ position: "fixed" }}
                  ></i>
                ) : (
                  <i
                    class="fas fa-eye tl mt3  pointer right-2-l right-35"
                    onClick={() => setPassShow(false)}
                    style={{ position: "fixed" }}
                  ></i>
                )}
              </div>

              <div className="flex flex-column w-100">
                <input
                  value={cnfPassword}
                  onChange={(e) => setCnfPassword(e.target.value)}
                  className="w-100 mt2 pa1"
                  type={`${showConfPass ? "text" : "password"}`}
                  placeholder="Confirm Password "
                />
                <p className="red fw6 f5 lh-copy">
                  {password === cnfPassword ? "" : cnfPassError}
                </p>
                {!showConfPass ? (
                  <i
                    onClick={() => setConfPassShow(true)}
                    class="fas pointer  fa-eye-slash tl mt3  pointer right-2-l right-35"
                    style={{ position: "fixed" }}
                  ></i>
                ) : (
                  <i
                    onClick={() => setConfPassShow(false)}
                    class="fas pointer  fa-eye tl mt3 right-2-l right-35 "
                    style={{ position: "fixed" }}
                  ></i>
                )}
              </div>
              <div className="w-100 flex flex-column items-center justify-center">
                <button
                  style={{
                    opacity:
                      fullName !== "" &&
                      reEmail.test(email) &&
                      password === cnfPassword &&
                      passwordCheck.test(password)
                        ? 1
                        : 0.4,
                  }}
                  onClick={() =>
                    fullName !== "" &&
                    reEmail.test(email) &&
                    password === cnfPassword &&
                    passwordCheck.test(password)
                      ? handleRegistration()
                      : alert("Fill all details")
                  }
                  className="w-70 pa2 br2  bg-red mt2  borderZero white pointer"
                >
                  Register
                </button>

                <p className="red tc  ttu fw6  mt2">
                  {alreadyerror && "Already register"}
                </p>
                <p className=" w-100 tc mt ttu fw6 mt2 green">
                  {showSuccess && "registration done successfully"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
